/* import __COLOCATED_TEMPLATE__ from './defects.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { formatNumber } from 'fabscale-app/utilities/utils/format-number';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';

interface Args {
  labSample: LabSample;
}

export default class RoastPicDetailsDefects extends Component<Args> {
  @service pdf: PdfService;

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-defects-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  drawDefectsChart(element: any) {
    const labels = Array.from(
      new Set(
        this.args.labSample.defectsHist?.map((countBin) => countBin.count)
      )
    );
    const ctx = element.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.args.labSample.defectsHist,
            backgroundColor: '#0F7799',
          },
        ],
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  get formattedCreatedDate() {
    const date = deserializeDate(this.args.labSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get totalNumberDefects() {
    const defectsHist = this.args.labSample.defectsHist;

    return defectsHist
      ?.filter((a) => a.count !== 0)
      .reduce((total, value) => total + value.value, 0);
  }

  get defectsPercentage() {
    const numberBeans = this.args.labSample.numberOfBeans;

    const defectsCatOne = this.args.labSample.numberDefectsCatOne;

    return ((defectsCatOne! / numberBeans!) * 100).toFixed(2);
  }

  get noDefectsPercentage() {
    const defectsCatOne = this.args.labSample.numberDefectsCatOne;
    const numberBeans = this.args.labSample.numberOfBeans;

    return (((numberBeans! - defectsCatOne!) / numberBeans!) * 100).toFixed(2);
  }

  get brokenDefectsPercentage() {
    const defectsCatOne = this.args.labSample.numberDefectsCatOne;
    const numberBeans = this.args.labSample.numberOfBeans;

    if (defectsCatOne !== undefined && numberBeans !== undefined) {
      return `${this.defectsPercentage}% ${defectsCatOne} of ${numberBeans}`;
    }

    return undefined;
  }

  get defectsHistItems() {
    const numberBeans = this.args.labSample.numberOfBeans;

    const defectsCatOne = this.args.labSample.numberDefectsCatOne;

    const defectsCatOneItem = {
      label: 'Chipped / Broken',
      value: defectsCatOne,
      percentage: `${formatNumber((defectsCatOne! / numberBeans!) * 100)}`,
      color: chartColors[0],
    };

    const noDefectsValue = numberBeans! - defectsCatOne!;
    const noDefectsItem = {
      label: 'No defects',
      value: noDefectsValue,
      percentage: `${formatNumber((noDefectsValue / numberBeans!) * 100)}`,
      color: chartColors[0],
    };

    return [noDefectsItem, defectsCatOneItem];
  }
}
